import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_ctx.generalMethods.valueExist(_ctx.value))
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        (_ctx.generalMethods.isPath(_ctx.value))
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createVNode(_component_inline_svg, {
                src: _ctx.value,
                style: {"width":"14px"}
              }, null, 8, ["src"])
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.value), 1))
      ]))
    : _createCommentVNode("", true)
}
<template>
	<span v-if="generalMethods.valueExist(value)">
		<span v-if="generalMethods.isPath(value)"><inline-svg :src="value" style="width: 14px" /></span>
		<span v-else>{{ value }}</span>
	</span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import * as generalMethods from "@/core/mixins/general_methods";

export default defineComponent({
	name: "kt-currency",
	props: {
		value: { type: String, default: null },
	},
	setup() {
		return {
			generalMethods,
		};
	},
});
</script>
